/**
 * Do not edit directly
 * Generated on Thu, 13 Feb 2025 00:44:59 GMT
 */

export const borderRadiusSm = "0.25rem";
export const borderRadiusLg = "0.5rem";
export const borderRadiusXl = "1rem";
export const borderRadiusRound = "6.25rem";
export const colorGreen100 = "#dae7d6";
export const colorGreen200 = "#b4cead";
export const colorGreen300 = "#8fb685";
export const colorGreen400 = "#699d5c";
export const colorGreen500 = "#448533";
export const colorGreen600 = "#396e2f";
export const colorGreen700 = "#2e572b";
export const colorGreen800 = "#234027";
export const colorGreen900 = "#182923";
export const colorGreen050 = "#ecf3eb";
export const colorYellow100 = "#fef0cd";
export const colorYellow200 = "#fce09c";
export const colorYellow300 = "#fbd16a";
export const colorYellow400 = "#fac138";
export const colorYellow500 = "#e4aa1b";
export const colorYellow600 = "#c79e3b";
export const colorYellow700 = "#987b34";
export const colorYellow800 = "#6a582d";
export const colorYellow900 = "#3b3526";
export const colorYellow050 = "#fef9ec";
export const colorRed100 = "#f3d7d7";
export const colorRed200 = "#e6afae";
export const colorRed300 = "#da8686";
export const colorRed400 = "#cd5e5d";
export const colorRed500 = "#c13635";
export const colorRed600 = "#9d2f31";
export const colorRed700 = "#79282c";
export const colorRed800 = "#552028";
export const colorRed900 = "#311923";
export const colorRed050 = "#f9ebeb";
export const colorGrey100 = "#e2e3e4";
export const colorGrey200 = "#c5c6c9";
export const colorGrey300 = "#a8aaaf";
export const colorGrey400 = "#8b8d94";
export const colorGrey500 = "#6e7179";
export const colorGrey600 = "#5b5e67";
export const colorGrey700 = "#474b55";
export const colorGrey800 = "#2d3039";
export const colorGrey900 = "#1d212b";
export const colorGrey020 = "#fafafa";
export const colorGrey050 = "#f0f1f1";
export const colorGreyA100 = "#d5d5d5";
export const colorGreyA200 = "#aaaaaa";
export const colorGreyA400 = "#616161";
export const colorGreyA700 = "#303030";
export const colorEggplant100 = "#dfdafe";
export const colorEggplant200 = "#bfb6fd";
export const colorEggplant300 = "#9f91fd";
export const colorEggplant400 = "#7f6dfc";
export const colorEggplant500 = "#5f48fb";
export const colorEggplant600 = "#4f3dcf";
export const colorEggplant700 = "#3e32a3";
export const colorEggplant800 = "#2e2877";
export const colorEggplant900 = "#1d1d4b";
export const colorEggplant050 = "#efedff";
export const colorBlue100 = "#d1e4f6";
export const colorBlue200 = "#a3c8ed";
export const colorBlue300 = "#75ade4";
export const colorBlue400 = "#4791db";
export const colorBlue500 = "#1976d2";
export const colorBlue600 = "#1762ae";
export const colorBlue700 = "#144e8a";
export const colorBlue800 = "#123a67";
export const colorBlue900 = "#0f2643";
export const colorBlue050 = "#e8f1fa";
export const colorKale100 = "#cceffc";
export const colorKale200 = "#99dff9";
export const colorKale300 = "#66d0f6";
export const colorKale400 = "#33c0f3";
export const colorKale500 = "#00b0f0";
export const colorKale600 = "#0390c6";
export const colorKale700 = "#05719c";
export const colorKale800 = "#085173";
export const colorKale900 = "#0a3249";
export const colorKale050 = "#e5f7fd";
export const colorBlack = "#000000";
export const colorWhite = "#ffffff";
export const colorBlank = "rgba(255, 255, 255, 0.01)";
export const colorOpacity1 = "08";
export const colorOpacity2 = "12";
export const colorOpacity3 = "30";
export const colorOpacity4 = "40";
export const colorOpacity5 = "50";
export const colorOpacity6 = "60";
export const colorOpacity7 = "70";
export const colorOpacity8 = "80";
export const colorOpacity9 = "90";
export const colorOpacity05 = "04";
export const fontWeightLight = "300";
export const fontWeightRegular = "400";
export const fontWeightSemibold = "600";
export const fontWeightBold = "700";
export const fontWeightBolder = "900";
export const fontSizeXs = "0.75rem";
export const fontSizeSm = "0.875rem";
export const fontSizeBase = "1rem";
export const fontSizeLg = "1.125rem";
export const fontSizeXl = "1.25rem";
export const fontSize2xl = "1.5rem";
export const fontSize3xl = "1.75rem";
export const fontSize4xl = "2rem";
export const fontSize5xl = "2.25rem";
export const fontSize6xl = "2.625rem";
export const fontSize7xl = "3rem";
export const fontSize8xl = "6rem";
export const fontHeading1 = "3rem";
export const letterSpacingXs = "-0.08rem";
export const letterSpacingSm = "-0.03rem";
export const letterSpacingReg = "0";
export const letterSpacingLg = "0.01rem";
export const letterSpacingXl = "0.05rem";
export const lineHeightSingle = "1";
export const lineHeightShort = "1.2";
export const lineHeightLong = "1.5";
export const lineHeightDouble = "2";
export const size8xs = "1rem";
export const size7xs = "1.5rem";
export const size6xs = "2rem";
export const size5xs = "3rem";
export const size4xs = "4rem";
export const size3xs = "8rem";
export const size2xs = "15.625rem";
export const sizeXs = "21.875rem";
export const sizeSm = "37.5rem";
export const sizeMd = "56.25rem";
export const sizeLg = "75rem";
export const sizeXl = "96rem";
export const spacing4xs = "0.0625rem";
export const spacing3xs = "0.125rem";
export const spacing2xs = "0.25rem";
export const spacingXs = "0.5rem";
export const spacingSm = "0.75rem";
export const spacingNone = "0";
export const spacingBase = "1rem";
export const spacingLg = "1.25rem";
export const spacing2lg = "1.5rem";
export const spacingXl = "2rem";
export const spacing2xl = "2.5rem";
export const spacing3xl = "3rem";
export const spacing4xl = "4rem";
export const spacing5xl = "5rem";
export const spacing6xl = "6rem";
export const spacing7xl = "10rem";
export const spacingMarginBottom = "1.25rem";
export const brandColorPrimary = "Eggplant";
export const brandColorSecondary = "Kale";
export const brandColorError = "Red";
export const brandColorWarning = "Yellow";
export const brandColorSuccess = "Green";
export const brandColorInfo = "Blue";
